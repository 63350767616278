<template>
    <div class="mainlist">
        <el-form v-if="gnrhPhysicalForm.gender === 1" class="mainContent" label-width="0">
            <el-form-item>
                <div class="question-title" style="margin-top: 10px;">体征记录</div>
            </el-form-item>
            <el-form-item>
                <div class="firsttitle" style="margin-bottom:0;">第一性征</div>
            </el-form-item>
            <el-form-item>
                <el-row class="questionmain">
                    <el-col :span="12">晨勃现象</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer1 === 1">存在</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer1 === 2">消失</el-col>
                </el-row>
                <el-row class="questionmain">
                    <el-col :span="12">性生活频率（含自慰）</el-col>
                    <el-col :span="12">{{gnrhPhysicalForm.answer2}}次/月</el-col>
                </el-row>
                <el-row class="questionmain">
                    <el-col :span="12">勃起功能</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer3 === 1">正常</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer3 === 2">疲软</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer3 === 3">障碍</el-col>
                </el-row>
                <el-row class="questionmain">
                    <el-col :span="12">睾丸大小</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer4 === 1">增大</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer4 === 2">萎缩</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer4 === 3">无变化</el-col>
                </el-row>
                <el-row class="questionmain">
                    <el-col :span="12">乳腺变化</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer5 === 1">增大</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer5 === 2">萎缩</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer5 === 3">无变化</el-col>
                </el-row>
            </el-form-item>
            <el-form-item>
                <div class="firsttitle" style="margin-bottom:0;">第二性征</div>
            </el-form-item>
            <el-form-item>
                <el-row class="questionmain">
                    <el-col :span="12">头发变化</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer6 === 1">增加</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer6 === 2">减少</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer6 === 3">无变化</el-col>
                </el-row>
                <el-row class="questionmain">
                    <el-col :span="12">胡须浓度变化</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer7 === 1">增加</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer7 === 2">减少</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer7 === 3">无变化</el-col>
                </el-row>
                <el-row class="questionmain">
                    <el-col :span="12">阴毛变化</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer8 === 1">增加</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer8 === 2">减少</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer8 === 3">无变化</el-col>
                </el-row>
                <el-row class="questionmain">
                    <el-col :span="12">喉结变化</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer9 === 1">增大</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer9 === 2">变小</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer9 === 3">无变化</el-col>
                </el-row>
                <el-row class="questionmain">
                    <el-col :span="12">对异性兴趣</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer10 === 1">增加</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer10 === 2">减退</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer10 === 3">无变化</el-col>
                </el-row>
            </el-form-item>
            <el-form-item>
                <div class="firsttitle" style="margin-bottom:0;">身体素质</div>
            </el-form-item>
            <el-form-item>
                <el-row class="questionmain">
                    <el-col :span="12">力量</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer11 === 1">增强</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer11 === 2">维持</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer11 === 3">减弱</el-col>
                </el-row>
                <el-row class="questionmain">
                    <el-col :span="12">体能</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer12 === 1">增强</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer12 === 2">维持</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer12 === 3">减弱</el-col>
                </el-row>
                <el-row class="questionmain">
                    <el-col :span="12">记忆力</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer13 === 1">增强</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer13 === 2">维持</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer13 === 3">减弱</el-col>
                </el-row>
                <el-row class="questionmain">
                    <el-col :span="12">是否易怒</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer14 === 1">有</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer14 === 0">无</el-col>
                </el-row>
            </el-form-item>
        </el-form>
        <el-form v-if="gnrhPhysicalForm.gender === 0" class="mainContent" label-width="0">
            <el-form-item>
                <div class="question-title" style="margin-top: 10px;">体征记录</div>
            </el-form-item>
            <el-form-item>
                <div class="firsttitle" style="margin-bottom:0;">第一性征</div>
            </el-form-item>
            <el-form-item>
                <el-row class="questionmain">
                    <el-col :span="12">月经周期</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer1 === 1">规律</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer1 === 2">异常</el-col>
                </el-row>
                <el-row class="questionmain">
                    <el-col :span="12">乳腺变化</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer2 === 1">增大</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer2 === 2">萎缩</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer2 === 3">无变化</el-col>
                </el-row>
            </el-form-item>
            <el-form-item>
                <div class="firsttitle" style="margin-bottom:0;">第二性征</div>
            </el-form-item>
            <el-form-item>
                <el-row class="questionmain">
                    <el-col :span="12">头发变化</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer3 === 1">增加</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer3 === 2">减少</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer3 === 3">无变化</el-col>
                </el-row>
                <el-row class="questionmain">
                    <el-col :span="12">阴毛变化</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer4 === 1">增加</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer4 === 2">减少</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer4 === 3">无变化</el-col>
                </el-row>
                <el-row class="questionmain">
                    <el-col :span="12">对异性兴趣</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer5 === 1">增加</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer5 === 2">减退</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer5 === 3">无变化</el-col>
                </el-row>
            </el-form-item>
            <el-form-item>
                <div class="firsttitle" style="margin-bottom:0;">身体素质</div>
            </el-form-item>
            <el-form-item>
                <el-row class="questionmain">
                    <el-col :span="12">力量</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer6 === 1">增强</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer6 === 2">维持</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer6 === 3">减弱</el-col>
                </el-row>
                <el-row class="questionmain">
                    <el-col :span="12">体能</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer7 === 1">增强</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer7 === 2">维持</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer7 === 3">减弱</el-col>
                </el-row>
                <el-row class="questionmain">
                    <el-col :span="12">记忆力</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer8 === 1">增强</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer8 === 2">维持</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer8 === 3">减弱</el-col>
                </el-row>
                <el-row class="questionmain">
                    <el-col :span="12">是否易怒</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer9 === 1">有</el-col>
                    <el-col :span="12" v-if="gnrhPhysicalForm.answer9 === 2">无</el-col>
                </el-row>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        name: "GnrhPhysicalDetail",
        data() {
            return {
                gnrhPhysicalForm: {
                },
                token: '',
                uuid: ''
            }
        },
        mounted() {
            this.token = this.$route.query.token;
            this.uuid = this.$route.query.uuid;
            this.getGnrhPhysicalRecord();
        },
        methods: {
            getGnrhPhysicalRecord() {
                this.$axios({
                    method: "get",
                    url: "/api/api/patientGnrhPhysicalRecord/detail/" + this.uuid
                }).then(res => {
                    if(res.data.code === 200) {
                        this.gnrhPhysicalForm = res.data.data;
                    } else {
                        this.$message({
                            message: res.data.message,
                            type: "error"
                        });
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .mainlist {
        padding: 0;
    }
    .mainContent {
        overflow-x: hidden;
        background-color: #fff;
        border-radius: 10px;
        padding: 0 10px 1.25rem 10px;
    }
    .question-title {
        height: 3.125rem;
        line-height: 3.125rem;
        margin: 0;
        text-align: center;
        font-size: 1.375rem;
    }
    .firsttitle {
        font-size: 1rem;
        padding: 0 10px;
        height: 2.875rem;
        line-height: 2.875rem;
        border-bottom: 1px dashed #ddd;
        border-top: 1px dashed #ddd;
        background-color: #f8f9fc;
    }
    .questionmain {
        font-size: 0.875rem;
        margin: 0.625rem;
        border-bottom: 1px dashed #ddd;
    }
</style>
